import { createSignal, onMount } from 'solid-js';
import { firePixel, useTrackEvent } from '@troon/analytics';
import { useSearchParams } from '@solidjs/router';
import type { StripeElements } from '@stripe/stripe-js';

type Props = {
	elements: StripeElements;
};

export function PaymentElement(props: Props) {
	const [ref, setRef] = createSignal<HTMLDivElement>();
	const [paymentType, setPaymentType] = createSignal('card');
	const [complete, setComplete] = createSignal(false);
	const trackEvent = useTrackEvent();
	const [params] = useSearchParams<{ id: string }>();

	onMount(() => {
		const el = props.elements.create('payment', {
			wallets: { applePay: 'auto', googlePay: 'auto' },
		});
		el.on('change', (e) => {
			if (paymentType() !== e.value.type) {
				trackEvent('changePaymentType', { paymentType: e.value.type, productId: params.id });
				setPaymentType(e.value.type);
			}

			if (e.complete && !complete()) {
				trackEvent('paymentInfoComplete', { paymentType: e.value.type, productId: params.id });
				firePixel('add-payment-info');
			}
			setComplete(e.complete);
		});

		el.mount(ref()!);
	});

	return <div ref={setRef} />;
}
