import { Details, SquareLogo, Summary } from '@troon/ui';
import { For } from 'solid-js';
import { Icon } from '@troon/icons';
import type { TroonCardSubscriptionProduct } from '../../../../graphql';

type Props = TroonCardSubscriptionProduct;

export function ProductInfo(props: Props) {
	return (
		<div class="relative overflow-hidden bg-gradient-to-r from-neutral-950 to-brand-700 px-6 pb-6 pt-16 text-white lg:pt-36">
			<SquareLogo class="absolute -right-12 -top-16 z-0 size-64 origin-center rotate-12 text-brand opacity-20" />
			<div class="relative z-0 flex flex-col gap-6 text-base">
				<div class="flex flex-col gap-2">
					<p class="text-xl font-semibold">{props.subscriptionName}</p>
					<p>{props.amount.displayValue}/year</p>
				</div>

				<hr class="border-white/20" />

				<Details>
					<Summary>Membership details</Summary>

					<div class="flex flex-col gap-4">
						<ul class="flex flex-col gap-2">
							<For each={props.valueProps}>
								{(value) => (
									<li class="flex flex-row items-center gap-2">
										<Icon name="check" class="shrink-0 text-xl text-brand" />
										{value}
									</li>
								)}
							</For>
						</ul>
					</div>
				</Details>
			</div>
		</div>
	);
}
